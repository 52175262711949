<template>
	<div class="multiple">
		<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{datas.node_name}}</el-checkbox>
		<div style="margin: 15px 0;"></div>
		<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
			<el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
		</el-checkbox-group>
	</div>
</template>

<script>
	export default {
		name: 'multiple',
		data() {
			return {
				checkAll: false,
				isIndeterminate: false,
				checkedCities: [],//默认选中
				cities: [],
			}
		},
		props: {
			datas:{
				type:Object
			},
			index:{
				type:Number
			},
			defaults:{
				type:Array
			},
		},
		components: {

		},
		created() {
			for(var i =0;i<this.datas.son.length;i++){
				this.cities.push(this.datas.son[i].node_name);
			}
			this.checkedCities=this.defaults;
			console.log('sss',this.datas.length)
			if(this.datas.length>1){
				this.isIndeterminate = true;
			}
			// this.checkedCities = true ? this.cities : [];
			// this.isIndeterminate = true;
			// console.log(this.defaults);
		},
		methods: {
			handleCheckAllChange(val) {
				this.checkedCities = val ? this.cities : [];
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			}
		},
		computed: {

		},
	}
</script>

<style scoped="scoped">
	.el-checkbox-group{
		padding-left: 50px;
	}
	.multiple{
		height: 100px;
	}
</style>
