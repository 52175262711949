 <template>
  <tabbar :top1="top1" :top2="top2">
    <!-- <contentclk ref='clk' v-for="item,index in data" :datas='item' :index='index' :defaults='defaults'></contentclk> -->
    <div style="width: 83.5%; margin-left: 15%; margin-top: 20px">
      <el-tree
        :data="datas"
        show-checkbox
        default-expand-all
        node-key="node_id"
        ref="tree"
        highlight-current
        :default-expanded-keys="mainId"
        :default-checked-keys="afterMain"
        :props="defaultProps"
      >
      </el-tree>
      <div class="buttons">
        <el-button @click="getCheckedNodes">确定</el-button>
      </div>
    </div>
  </tabbar>
</template>

<script>
import tabbar from "../public/Frame.vue";
import contentclk from "./comp/Multiple.vue";
export default {
  name: "jurisdiction",
  data() {
    return {
      data: null,
      top1: "3-2",
      top2: ["3"],
      arr_maintain: {},
      main: [],
      mainId: [],
      afterMain: [],
      datas: [{}],
      defaultProps: {
        children: "son",
        label: "node_name",
      },
    };
  },
  components: {
    contentclk,
    tabbar,
  },
  created() {
    let id = this.$request.getUrl("id");
    // console.log(id);
    let token = this.$storage.getLocal("token");
    this.$request
      .roleAddList({
        token,
        id,
      })
      .then((res) => {
        console.log(res);
        if (res.code == 0) {
          let mainId = [];
          this.datas = res.data.AllMenu;
          // this.afterMain = res.data.nodeIds;
          for (var k = 0; k < res.data.AllMenu.length; k++) {
            mainId.push(res.data.AllMenu[k].node_id);
            // console.log(this.mainId)
          }
          // let frist = arr3.filter((item) => arr1.indexOf(item) > -1);
          this.mainId = mainId;
          let arr = res.data.nodeIds.filter(
            (item) => mainId.indexOf(item) == -1
          );
          this.afterMain = arr
          console.log(arr);
        }
      });
  },
  methods: {
    getCheckedNodes() {
      // console.log(this.$refs.tree.getCheckedNodes());

      let fatherid = [];
      let filsid = [];
      var mId = this.$refs.tree.getCheckedNodes();
      console.log(this.$refs.tree.getCheckedNodes());
      for (var i = 0; i < mId.length; i++) {
        if (mId[i].level == 1) {
          fatherid.push(mId[i].node_id);
        } else {
          fatherid.push(mId[i].pid);
          filsid.push(mId[i].node_id);
        }
      }
      const set = new Set(fatherid);
      fatherid = [...set];

      let id = this.$request.getUrl("id");
      let token = this.$storage.getLocal("token");
      this.$request.roleAdd({ id, token, fatherid, filsid }).then((res) => {
        const h = this.$createElement;
        this.$notify({
          title: "消息提示",
          message: h("i", { style: "color: red;font-size:28px" }, "设置成功"),
        });
        this.$router.push({ path: "/role" });
        console.log("666", res);
      });
    },
  },
  computed: {},
};
</script>

<style scoped="scoped">
</style>

 